var defaultConf = require('./config-default');
var BO_URL = 'pdw0923-data.ms-dev.mobile-spot.com';
var BO_TAIGA_URL = 'https://pgorganisation.site.calypso-event.net';
var NODE_BACKEND_DOMAIN = 'pdw-node-backend-dev.mobile-spot.com';
var NODE_WS_BAKEND_DOMAIN = 'pdw-node-backend-ws-dev.mobile-spot.com';
module.exports = require('deepmerge')(defaultConf, {
  ENV: 'dev',
  BO_URL: BO_URL,
  // no trailing '/'
  BO_PATH: '/home/legacy/safi/pdw/pdw0923-data.ms-dev.mobile-spot.com',
  // no trailing '/'
  BO_SERVER_ALIAS: 'ms-dev.mobilespot.prodleni.local',
  BO_SSH_USER: 'legacy',
  BO_SSH_PORT: '43122',
  DEPLOY_PATH: '/home/legacy/safi/pdw/pdw0923.ms-dev.mobile-spot.com',
  //GOOGLE_ANALYTICS_ID: 'UA-48506479-33',
  GOOGLE_ANALYTICS_ID_V4: 'G-378221626',
  LOGIN: {
    forgottenPasswordURL: "".concat(BO_TAIGA_URL, "/mobile-se/mot-de-passe-oublie.htm")
  },
  projectId: '456727876895',
  // FCM sender id
  appId: '9D16C-B905A',
  // pushwoosh appid

  SEND_EXPORTED_NOTES_MAIL: {
    WS_URL: 'http://localhost:3096/api/exportNotesByMail',
    EXPEDITOR: 'no-reply@mobile-spot.com',
    KEY: 'zpgZkgiz845gjzFzi'
  },
  ADDITIONAL_DEFAULTSRC_CSP: (Array.isArray(defaultConf.ADDITIONAL_DEFAULTSRC_CSP) ? defaultConf.ADDITIONAL_DEFAULTSRC_CSP : []).concat([]),
  CHECK_NETWORK_URL: "https://".concat(BO_URL, "/online.txt"),
  SYNCHRONIZED_FAVORITES: {
    FEATURE_ENABLED: false,
    MODE: 'peering',
    // (peering or login) 
    URL: "https://".concat(NODE_BACKEND_DOMAIN, "/synchro-favorites")
  },
  REAL_TIME: {
    URL: "https://".concat(NODE_WS_BAKEND_DOMAIN)
  },
  SOCIAL: {
    FEATURE_ENABLED: false,
    TWITTER: {
      POST_PER_PAGE: 10
    },
    YOUTUBE: {
      POST_PER_PAGE: 5
    },
    URL: "https://".concat(NODE_BACKEND_DOMAIN, "/social")
  }
});