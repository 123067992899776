import _defineProperty from "/Users/vagrant/git/mspot-pdw/app-react/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
var _fields;
export default {
  id: 'en',
  common: {
    appTitle: 'PDW',
    colon: ': ',
    questionMark: '?',
    noResult: 'No result',
    yes: 'Yes',
    no: 'No',
    ok: 'OK',
    confirm: 'Confirm',
    cancel: 'Cancel',
    close: 'Close',
    keep: 'Keep',
    delete: 'Delete',
    camera: 'Camera',
    gallery: 'Gallery',
    chooseLang: 'Choose language',
    addToFavorites: 'Add to favorites',
    addNote: 'Add a note',
    editNote: 'Edit the note',
    defaultPlaceLabel: 'Map',
    noNetworkTitle: 'Network unavailable',
    noNetwork: 'Please try again later',
    loading: 'Please wait...',
    showAll: 'Show all on map',
    noPlacesToShowOnMap: 'No related place to show on map',
    termsOfUse: 'Terms of use',
    information: 'Information',
    display: 'Display',
    switch2Columns: 'Switch to a page setup optimized for your screen width? (recommended)',
    share: 'Share',
    goBack: 'Go back',
    goToHome: 'Go to home',
    goToRootCategory: 'Go back to section home',
    login: 'Log in',
    goToFavorites: 'See your favorites',
    goToInbox: 'See messages',
    goToSearch: 'Search through content',
    goToFilter: 'Filter content',
    goToSynopticAgenda: 'View synoptic',
    goBackToAgenda: 'Back to events list',
    openMenu: 'Open menu',
    relatedContent: 'Related Content',
    contributions: 'Contributions',
    feedback: 'Feedback',
    rateThisSession: 'Rate this session',
    seeMore: 'see more ...',
    seeLess: 'see less ...',
    accept: 'Accept',
    deny: 'Deny',
    continue: 'Continue',
    noData: 'No data available',
    navigationDenied: 'You must be authenticated to access this page.',
    navigationDeniedwithoutLogin: 'You do not have the right to access this page.',
    save: 'Save',
    saving: 'Saving...',
    saved: 'Saved',
    invalidAuth: 'Invalid or expired session',
    updateFailed: 'Update failed',
    error: 'An error occured',
    contact: 'Contact',
    contacted: 'Contacted',
    contactConfirmMessage: 'A contact request will be sent by email to this person on your behalf. If she/he accepts the request, she/he will contact you back by mail.',
    contactErrorTitle: 'Failed to contact participant',
    contactError: 'Please try again later.',
    fetchError: 'Failed to retrieve data',
    mandatory: ' <small><i> - mandatory</i></small>',
    mandatoryInPlaceholder: ' (mandatory)',
    emptyReadOnlyField: 'N/A',
    smartbannerViewButton: 'VIEW',
    seeDetails: 'See details',
    seeAll: 'See all',
    filterByCategory: 'Filter by itinery',
    aboutCompany: 'About the company',
    contactScan: 'Contact Scan',
    KlipsoLeads: 'Klipso Leads',
    KlipsoSso: 'Klipso SSO',
    OAuth2: 'Connect to B-Com',
    pdfBtnTitle: 'PDF'
  },
  formErrors: {
    missing: 'Value is mandatory',
    invalid: 'Value is invalid'
  },
  location: {
    feature: 'Location',
    yourLocation: 'Your position'
  },
  disclaimer: {
    text: 'Cookies are used with this app to compute anonymous usage statistics.'
  },
  share: {
    mail: 'Share by email',
    facebook: 'Share on Facebook',
    linkedin: 'Share on LinkedIn',
    twitter: 'Share on Twitter',
    positionShare: 'Position shared',
    // favorite position share description
    shareFail: 'Failed to share',
    linkedinShareDone: 'Page shared on your LinkedIn profile',
    linkedinShareFail: 'Failed to share on LinkedIn',
    facebookShareDone: 'Page shared on your Facebook profile',
    facebookShareFail: 'Failed to share on Facebook'
  },
  data: {
    gmapplaces: {
      singular: 'Place',
      plural: 'Places',
      title: 'Places'
    },
    brands: {
      singular: 'Brand',
      plural: 'Brands',
      title: 'Brands'
    },
    brand_categories: {
      singular: 'Brand category',
      plural: 'Brand categories',
      title: 'Brands'
    },
    exhibitors: {
      singular: 'Participants',
      plural: 'Participants',
      title: 'Participants',
      searchPlaceholder: 'Participants'
    },
    exhibitor_categories: {
      singular: 'Participant category',
      plural: 'Participant categories',
      title: 'Participants'
    },
    kleads_contacts: {
      singular: 'contact',
      plural: 'contacts'
    },
    restaurants: {
      singular: 'Restaurant',
      plural: 'Restaurants',
      title: 'Restaurants'
    },
    restaurant_categories: {
      singular: 'Restaurant category',
      plural: 'Restaurant categories',
      title: 'Restaurants'
    },
    monuments: {
      singular: 'Well worth a visit',
      plural: 'Well worth a visit',
      title: 'Well worth a visit'
    },
    services: {
      singular: 'Information',
      plural: 'Information',
      title: 'Information'
    },
    service_categories: {
      singular: 'Information category',
      plural: 'Information categories',
      title: 'Information'
    },
    aircrafts: {
      singular: 'Aircraft',
      plural: 'Aircraft',
      title: 'Aircraft'
    },
    aircraft_categories: {
      singular: 'Aircraft category',
      plural: 'Aircraft categories',
      title: 'Aircraft'
    },
    events: {
      singular: 'Events',
      plural: 'Events',
      title: 'Events',
      subevents: 'Speeches'
    },
    event_categories: {
      singular: 'Event category',
      plural: 'Event categories',
      title: 'Events'
    },
    conferences: {
      singular: 'Conference',
      plural: 'Conferences'
    },
    speakers: {
      singular: 'Speaker',
      plural: 'Speakers',
      title: 'Speakers',
      moderators: 'Moderators'
    },
    papers: {
      singular: 'Abstract',
      plural: 'Abstracts',
      title: 'Abstracts'
    },
    paper_categories: {
      singular: 'Abstract',
      plural: 'Abstracts',
      title: 'Abstracts'
    },
    newproducts: {
      singular: 'Product',
      plural: 'Products',
      title: 'Products'
    },
    newproduct_categories: {
      singular: 'Product category',
      plural: 'Product categories',
      title: 'Products'
    },
    animations: {
      plural: 'Experiences',
      title: 'Experiences',
      singular: 'Experience',
      go: 'See on map'
    },
    stores: {
      singular: 'Participant In The City',
      plural: 'Participants In The City',
      title: 'Participants In The City'
    },
    store_categories: {
      singular: 'Participant In The City',
      plural: 'Participants In The City',
      title: 'Participants In The City'
    },
    favoritepositions: {
      plural: 'Locations',
      singular: 'Location'
    },
    participants: {
      singular: 'Contacts',
      plural: 'Contacts',
      title: 'Contacts'
    },
    contacted_participants: {
      singular: 'My contact requests',
      plural: 'My contact requests',
      title: 'My contact requests'
    },
    meetingContacts: {
      singular: 'My appointments',
      plural: 'My appointments',
      title: 'My appointments'
    },
    contacts: {
      singular: 'Contacts',
      plural: 'Contacts',
      title: 'Contacts'
    },
    flights: {
      singular: 'Flight',
      plural: 'Flights'
    },
    flights_schedule: {
      locale: 'en',
      // wtf ?
      longDateFormat: 'L h:mm:ss a',
      title: 'Flights Schedule',
      refresh: 'Refresh',
      unknownAircraft: 'Unknown aircraft',
      notScheduledYet: 'Not scheduled yet',
      errorWhileFetching: 'An error occurred',
      lastRefreshed: 'Last update : ',
      noResults: 'Flights are not scheduled yet'
    },
    docunits: {
      singular: 'Press kit',
      plural: 'Press kits'
    }
  },
  dataListDialog: {
    defaultTitle: 'todo default title'
  },
  size: {
    bytes: ' bytes',
    kilobytes: ' kB',
    megabytes: ' mB'
  },
  update: {
    modalTitle: 'Update',
    update_detected: 'Proceed to data update ?',
    cancel_update: 'Cancel update',
    update_done: 'Update completed',
    new_version_detected: 'New version detected',
    timeout: 'Timeout'
  },
  lang: {
    fr: 'french',
    en: 'english',
    zh: 'chinese'
  },
  menu: {
    restaurants: 'Restaurants',
    hours: 'Opening hours',
    inbox: 'Inbox',
    brands: 'Brands',
    exhibitors: 'Participants',
    exhibitorsAndNp: 'Exhibitors & products',
    services: 'Practical information',
    speakers: 'Speakers',
    map: 'Map',
    agenda: 'Events',
    experiences: 'Experiences',
    medias: 'Social Media',
    update: 'Update',
    language: 'Language',
    close: 'Close menu',
    pmr: 'MIP',
    toggleProfile: 'Change profile',
    aircraftsAndFlights: 'Aircraft & Flights',
    livewall: 'LiveWall',
    twitter: 'Twitter',
    presskits: 'Press kits',
    papers: 'Abstracts',
    cgu: 'Terms of use',
    socialagenda: 'Social Programme',
    contacts: 'Contacts',
    userData: 'My profile',
    gmapplaces: 'GMap Places',
    news: 'News',
    talks: 'Talks'
  },
  home: {
    title: 'Home',
    myVisit: 'My visit',
    agenda: 'EVENTS',
    experiences: 'EXPERIENCES',
    exhibitors: 'PARTICIPANTS',
    exhibitorsAndProducts: 'EXHIBITORS & PRODUCTS',
    mapsAndGroups: 'Map & Groups',
    map: 'MAP',
    aircraftsAndFlights: 'AIRCRAFT & FLIGHTS',
    pmr: 'MIP',
    practicalInfo: 'PRACTICAL INFORMATION',
    medias: 'SOCIAL MEDIA',
    papers: 'ABSTRACTS',
    presskits: 'PRESS KITS',
    socialagenda: 'SOCIAL PROGRAMME',
    speakers: 'SPEAKERS',
    contacts: 'CONTACTS',
    gmapplaces: 'GMAP PLACES',
    news: 'NEWS',
    talks: 'TALKS'
  },
  profile: {
    title: 'Choose profile',
    mainLabel: 'Select your profile',
    codeInput: 'Enter the access code',
    invalidCode: 'Invalid access code',
    visitor: 'Visitor',
    exhibitor: 'Exhibitor',
    default: 'Default',
    'webapp-exh': 'Webapp-exh',
    'webapp-evt': 'Webapp-evt',
    'webapp-spk': 'Webapp-spk'
  },
  exhibitor: {
    contacts: 'Contacts',
    relatedContacts: 'Contacts on the event',
    entities: 'Entities',
    agentCountries: 'Agent countries',
    brands: 'Brands',
    activities: 'Activities',
    categories: 'Categories',
    metro: 'Metro',
    parcours: 'Parcours',
    quartier: 'District'
  },
  paper: {
    categories: 'categories',
    openPdf: 'OPEN PDF',
    authors: ''
  },
  placeSearch: {
    editStart: 'Edit start',
    setDestination: 'Next',
    itinerary: 'Itinerary',
    start: 'Choose your start point',
    destination: 'Destination',
    poi: 'POI'
  },
  aircraft: {
    pilot: 'Pilot',
    coPilot: 'Copilot',
    country: 'Country',
    manufacturer: 'Manufacturer',
    category: 'Category',
    usage: 'Usage',
    wingSpan: 'Wing span',
    length: 'Length',
    emptyWeight: 'Empty weight',
    maxCruiseSpeed: 'Max.cruise speed',
    characteristics: 'Characteristics',
    messageNoFlightYet: 'This aircraft is planned for flying displays. More information to come.'
  },
  newproducts: {
    launchDate: 'Launch date'
  },
  events: {
    to: 'to',
    access: 'Access',
    publics: 'Public',
    category: 'Category',
    quartier: 'District'
  },
  mobigeo: {
    title: 'Map',
    goTo: 'Go there',
    shouldReload: 'Map data has been updated, would you like to reload now?',
    reloadDueToUpdate: 'Map is updating…',
    errors: {
      // automatic matching. Refer to MobiGeo API doc to know the error codes
      ROUTE_ERROR_NO_ROUTE_FOUND: 'No itinerary found.',
      ROUTE_ERROR_USER_HAS_NO_POSITION: 'You are not located.'
    },
    bluetoothWarning: 'You need to enable bluetooth in your device settings',
    locationSettingWarning: 'You need to enable location in your device settings',
    goSettingsQuestionTitle: 'Permissions required',
    goSettingsQuestion: 'Would you like to go to your device settings to allow location ?'
  },
  map: {
    title: 'Map',
    goTo: 'Go there',
    shouldReload: 'Map data has been updated, would you like to reload now?',
    reloadDueToUpdate: 'Map is updating…',
    errors: {
      // automatic matching. Refer to MobiGeo API doc to know the error codes
      ROUTE_ERROR_NO_ROUTE_FOUND: 'No itinerary found.',
      ROUTE_ERROR_USER_HAS_NO_POSITION: 'You are not located.'
    },
    bluetoothWarning: 'You need to enable bluetooth in your device settings',
    locationSettingWarning: 'You need to enable location in your device settings',
    goSettingsQuestionTitle: 'Permissions required',
    goSettingsQuestion: 'Would you like to go to your device settings to allow location ?'
  },
  googleMap: {
    title: 'Map',
    goTo: 'Go there',
    shouldReload: 'Map data has been updated, would you like to reload now?',
    reloadDueToUpdate: 'Map is updating…',
    errors: {
      // automatic matching. Refer to MobiGeo API doc to know the error codes
      ROUTE_ERROR_NO_ROUTE_FOUND: 'No itinerary found.',
      ROUTE_ERROR_USER_HAS_NO_POSITION: 'You are not located.'
    },
    bluetoothWarning: 'You need to enable bluetooth in your device settings',
    locationSettingWarning: 'You need to enable location in your device settings',
    goSettingsQuestionTitle: 'Permissions required',
    goSettingsQuestion: 'Would you like to go to your device settings to allow location ?'
  },
  favorites: {
    title: 'Favorites',
    download: 'Download (excel)',
    localNotif: 'Toggle Test Local Notif',
    modeSync: 'Edit Synchronization mode',
    yourFavs: 'Your favorites',
    other: 'Other'
  },
  papers: {
    title: 'Abstracts'
  },
  agenda: {
    title: 'Events'
  },
  notes: {
    title: 'Note about',
    pageTitle: 'My notes',
    pageLinkTitle: 'Notes',
    delete: 'Delete the note',
    deleteAlert: 'Are you sure that you want to delete this note ?',
    exportButton: 'Export my notes',
    exportSuccess: 'Notes successfully sent by mail',
    errors: {
      INVALID_CREDENTIALS: 'Invalid credentials',
      INVALID_SMTP_CONFIGURATION: 'An error occured while sending the email',
      DEFAULT: 'An error occured while sending the email'
    },
    exportForm: {
      fields: {
        email: {
          label: 'Your email',
          validationMessage: 'Please enter a valid email address'
        }
      },
      title: 'Export your notes by email',
      subtitle: 'Please enter your email address and press the send button to receive your notes'
    }
  },
  medias: {
    title: 'Social Media',
    label: 'Follow our news on'
  },
  pmr: {
    title: 'MIP'
  },
  search: {
    title: 'Search',
    placeholderSuffix: '... (min. 2 char.)',
    submitLabel: 'Ok',
    searchFor: 'Search for '
    //        keywords: 'Keywords',
  },

  speaker: {
    relatedInterventions: 'Related Interventions'
  },
  hours: {
    title: 'Opening hours'
  },
  intersticiel: {
    closeLabel: 'Skip Ad'
  },
  promoMs: '',
  inbox: {
    title: 'Inbox',
    empty: 'You have no message',
    dismiss: 'Dismiss',
    confirmNotif: 'OK'
  },
  login: {
    title: 'Login',
    fillTheFields: 'Please fill in both fields before submitting',
    badLogin: 'The login or password you entered are incorrect',
    loginLabel: 'Login',
    loginPlaceholder: 'Enter login',
    passwordLabel: 'Password',
    passwordPlaceholder: 'Enter password',
    loginButton: 'Login',
    loginSuccess: 'You are logged-in',
    loginToSyncYourFavs: 'Enter the same username and password created when applying for the badge to synchronize your favourites between eurosatory.com & the mobile app!',
    introductoryText: '',
    forgottenPassword: 'I forgot my password',
    createAccount: 'Create an account',
    clichere: 'clic here',
    tos: 'Terms of use',
    profile: 'Profile',
    logout: 'Logout',
    noNetwork: 'You are not connected to the Internet',
    loading: 'Checking...',
    touMessage: 'By logging in you accept our '
  },
  userData: {
    title: 'Profile',
    notice: 'Changes need an Internet connection, and take several minutes to be taken into account.',
    fields: (_fields = {
      title: {
        label: 'Civility',
        placeholder: ''
      },
      profilePicture: {
        label: 'Profile picture',
        placeholder: ''
      },
      firstName: {
        label: 'First Name',
        placeholder: 'Please fill in your first name'
      },
      lastName: {
        label: 'Last Name',
        placeholder: 'Please fill in your last name'
      },
      organizationName: {
        label: 'Organisation',
        placeholder: 'Please fill in your organisation'
      },
      position: {
        label: 'Position',
        placeholder: 'Please fill in your position'
      },
      login: {
        label: 'Login',
        placeholder: 'Please enter your login'
      },
      email: {
        label: 'Contact Email',
        placeholder: 'Please fill in your email'
      },
      phone: {
        label: 'Phone',
        placeholder: 'Please fill in your phone number'
      },
      visible: {
        label: 'Visible in Contacts list',
        placeholder: ''
      },
      contactable: {
        label: 'Contactable by mail',
        placeholder: ''
      }
    }, _defineProperty(_fields, "login", {
      label: 'Login'
    }), _defineProperty(_fields, "departement", {
      label: 'Departement',
      placeholder: ''
    }), _fields),
    picture: {
      title: 'Profile picture',
      selectYours: 'Pick your portrait',
      pickError: 'Error while processing your picture',
      confirmRemoval: 'Do you confirm removal?',
      confirmSubstitution: 'You are about to replace your current profile picture.<br>Do you confirm this intent?'
    },
    updateSuccess: 'Profile updated',
    yourProfile: 'Your profile',
    yourEvents: 'Your conferences',
    contactedParticipantsSearchPlaceholder: 'Look for contacted participants',
    meetingContactsSearchPlaceholder: 'Look for appointments',
    externalResources: 'Your roadmap',
    externalResourcesNotice: 'This document may change. We invite you to check it from time to time.',
    badge: 'Your e-badge',
    userProjects: 'Visitor projects',
    noNetwork: 'You are not connected to the Internet',
    metContacts: 'Met contacts',
    contactsSearchPlaceholder: 'Search contacts',
    cantRemoveItemFromFavorites: 'You cannot remove from favorites an item linked to your profile.'
  },
  appointment: {
    button: 'Send a contact request',
    setProfileFirst: 'You must fill-in your profile to use this feature.',
    sent: function sent(formatedDate, email, phone) {
      return "Appointment request sent ".concat(formatedDate, ". Your recipient will answer you by email at ").concat(email).concat(phone ? " or by phone ".concat(phone) : '', ".");
    },
    errors: {
      GENERIC: 'Could not send the appointment request.',
      INVALID_REQUEST: 'An error occured while sending the appointment request.',
      EXHIBITOR_NOT_FOUND: 'An error occured while sending the appointment request.',
      EXHIBITOR_HAS_NO_CONTACT_EMAIL: 'An error occured while sending the appointment request.',
      ALREADY_SENT: 'An appointment request has already been sent to this exhibitor.'
    }
  },
  networking: {
    participantsFetchError: 'Error while fetching contact'
  },
  synchroFavs: {
    codePlaceholder: 'Enter code',
    favoritesPageNoticeUnauthorizedMode: 'You may synchronize your favorites between your mobile and web sessions by clicking on',
    favoritesPageNoticeExtraUnauthorizedMode: 'Your favorites are not shared with any third party nor used for any goal. You may stop the synchronization at any time by clicking on',
    favoritesPageNotice: 'Once logged-in, your favourites are synchronized across your user sessions on mobile and web.',
    logoutConfirm: 'Your favourites are no longer synchronized on this terminal. Do you want to keep them locally or delete them ?',
    logoutConfirmUnauthorizedMode: 'Your favourites will no longer be synchronized on this terminal. Do you want to keep them locally or delete them ?',
    status: {
      ongoing: 'Synchronizing your favourites',
      failed: 'Your favourites are not synchronized, check your data connection',
      success: 'Your favourites are synchronized'
    },
    haveCode: 'I have a code',
    noCode: "I don't have a code",
    syncCode: 'Synchronization code',
    localSyncCode: {
      singular: 'Used synchronization code',
      plural: 'Used synchronization codes'
    },
    enterCodeNotice: 'Enter your code to synchronize your favorites',
    codeCreatedNotice: 'The code below was created for you. Please press OK to synchronize your favorites',
    invalidCode: 'Invalid code',
    loadingCode: 'Loading code ...',
    comfirmDisableSynchro: "Do you really want to disable your favorites' synchronization ?",
    favoritesPageNoticeCodeSynchronised: 'Synchronized with code',
    onlyDigits: 'Numbers only'
  },
  poll: {
    listTile: 'Available Polls',
    noPolls: 'No poll available',
    sessionTitle: 'Session rating',
    sessionCodeError: 'INVALID CODE',
    fillTheFields: 'Please enter a code before submitting',
    code: 'code',
    enterCode: 'Please enter the session code',
    pleaseRate: 'Please rate',
    back: 'back',
    next: 'next',
    commentPlaceholder: 'your comment',
    thank: 'Thank you',
    submit: 'Submit',
    submitted: 'Already Submitted',
    done: 'Done',
    noNetwork: 'You have no internet connexion. You can press cancel now and Submit later. Your answers have been saved.',
    willOpen: 'This poll will be opened on',
    willClose: 'This poll will be closed on',
    isClosed: 'This poll is closed',
    requireAuthentication: 'Please Log In.',
    started: 'Started',
    notStarted: 'Not started',
    serverError: 'Server error. You can press cancel now and Submit later. Your answers have been saved.'
  },
  contributions: {
    lastRefreshed: 'Last update time',
    refresh: 'Refresh',
    ps: 'Current preferential subject (PS)',
    current: 'Current contribution',
    upcoming: 'Upcoming contributions',
    past: 'Past contributions',
    cancelled: 'Cancelled contributions',
    undefined: 'Undefined contributions',
    more: 'More',
    notBefore: 'not before',
    programmeSlogan: 'To get the Contributions schedule in real time, please go to each Group Discussion Meeting page in Technical Programme / Group Discussion Meetings',
    empty: 'No contributions scheduled yet'
  },
  docunit: {
    documents: 'Documents',
    document: 'Document',
    contacts: 'Contacts',
    presskit: 'Press kit'
  },
  filterDialog: {
    title: 'Filter',
    clear: 'Clear filter',
    showResults: 'Show results',
    found: {
      singular: ' found',
      plural: ' found'
    }
  },
  klipsoLeads: {
    title: 'Klipso Leads',
    androidScannerText: 'Place a barcode inside the scan area',
    scanError: 'Scan failed',
    scanDenied: 'Camera permission denied.',
    disclaimer: "<p>En tant qu'utilisateur de cette application, vous avez l'obligation d'indiquer à votre contact que :</p><ul><li>Son consentement est lié à son acceptation de se faire scanner son badge.</li><li>L'ensemble des données fournies lors de son inscription (coordonnées, activité, fonction, ..) à la manifestation sont susceptibles de vous être transmises (hors identifiants et mot de passe de connexion).</ul><p>Vous vous engagez à donner la possibilité de rectifier ou supprimer ses données dès que vous en aurez la possession, à chacun de vos contacts.</p><p>Vous vous engagez à respecter la confidentialité des données, de chacun de vos contacts, dès que vous en aurez la possession.</p>",
    accept: 'I ACCEPT',
    validate: 'VALIDATE',
    back: 'BACK',
    invalidLicence: 'Invalid licence',
    maxLicenceQuotaReached: 'The maximum number of users has been reached',
    lsEmptyLicenceAndUsername: 'Please fill in your user name and licence',
    lsEmptyLicence: 'Please fill in your licence',
    lsEmptyUsername: 'Please fill in your user name',
    alphanumericCharWarning: 'Only alphanumeric characters are allowed',
    licenceScanAttemptOfCryptedBadge: 'QR-Code de licence invalide',
    emptyLicenceOrUsername: 'Please fill in your user name and licence',
    licenceScreenFields: {
      licence: 'Licence key',
      userName: 'Type-in your LASTNAME Firstname'
    },
    placeholders: {
      userName: 'LASTNAME Firstname'
    },
    extendedMenuScreenFields: {
      licence: 'Licence key',
      userName: 'User name'
    },
    allContactsSync: ' all synchronized',
    contactsNotSync: {
      singular: ' non synchronized contact',
      plural: ' non synchronized contacts'
    },
    sortByDate: 'Sort by date',
    sortByCompany: 'Sort by company',
    listEmpty: 'No contact yet',
    disabledScanButtonMessage: 'The event is over.',
    invalidVisitorBadge: 'Invalid badge',
    close: 'CLOSE',
    cancel: 'CANCEL',
    save: 'SAVE',
    saveContactsError: function saveContactsError(count) {
      return count < 2 ? "".concat(count, " contact could not be saved:") : "".concat(count, " contacts could not be saved:");
    },
    formTitle: 'QUESTIONNAIRE',
    formNotifMandatoryFieldsToSet: 'Some mandatory fields need to be filled.',
    historyTitle: 'History',
    searchPlaceholder: 'First name, last name, company…',
    ongoingSync: 'Synchronization is ongoing…',
    resetLicence: 'Reset / Change licence key',
    resetLicenceConfirmTitle: 'WARNING  ⚠️',
    resetLicenceWithNonSyncContacts: "You can't reset your licence key when you have non synchronized contacts.",
    resetLicenceConfirm: '<b>WARNING</b><br><br>If you tap OK, Klipso Leads will be reset and your scanned contacts will be deleted on your smartphone. However, they were saved in your Exhibitor /  Badge reader web space.<br>If, after reset, you use the same license number anew, you will be considered as a new user, and may be rejected if your quota of users is reached.',
    exportContacts: 'Export my contacts',
    tos: 'Terms of use',
    noAuthorization: 'The maximum number of users has been reached'
  },
  contacts: {
    scanError: 'Scan failed',
    qrCodeUnHnadled: 'Unhandled QRCode',
    scanDenied: 'Camera permission denied.',
    disclaimer: "<p>This badge reader allows you to scan the QR-code printed on another person's badge in order to pre-fill a contact sheet which you can save in the app (My Profile / tab My Contacts) and export to your Contacts on your smartphone.</p><p>You must always ask the person's authorization before scanning her badge.</p><p>The pre-filled data are : lasname, firstname, organisation, role if available. To get an email address or a phone number, you shall ask it to the person.</p><p>Before taking a picture of the person you shall ask her authorization.</p>",
    disclaimerOk: 'I GOT IT',
    disclaimerNOk: 'CANCEL',
    loginNeeded: 'You must be authenticated to use the badge reader',
    contactExist: 'This contact is already in your Contacts list, do you want to update it ?',
    photoPermission: 'Please ask the person’s authorization before capturing her portrait',
    form: {
      title: 'Contact',
      subTitle: '',
      fields: {
        profilePicture: 'Profile picture',
        firstName: 'First name',
        lastName: 'Last Name',
        organizationName: 'Organization',
        role: 'Role',
        email: 'Email',
        phone: 'Office Tel.',
        mobilePhone: 'Mobile',
        address: 'Address'
      }
    }
  },
  searchTaiga: {
    title: 'Search - ',
    search: 'Search',
    goToSimpleSearch: 'Back to simple search',
    goToAdvancedSearch: 'Advanced search',
    editSearch: 'Modify search',
    emptyParameters: 'Please fill at least one field',
    fields: {
      search: {
        label: 'Recherche',
        placeholder: 'Nom, organisation,...'
      },
      profil: {
        label: 'Type de structure',
        placeholder: 'Indiquez les types de structure recherchées'
      },
      name: {
        label: 'Nom',
        placeholder: 'Entrer un nom'
      },
      company: {
        label: 'Société/Organisme',
        placeholder: 'Société/Organisme'
      },
      role: {
        label: 'Fonction',
        placeholder: 'Indiquez les fonctions recherchées'
      },
      service: {
        label: 'Service/Pôle',
        placeholder: 'Indiquez les services recherchés'
      },
      centerinterest: {
        label: "Centres d'intérêt",
        placeholder: "Indiquez les centre d'intérêt recherchés"
      }
    },
    fieldToggle: {}
  },
  formModal: {
    cancel: 'Cancel',
    send: 'Send'
  },
  permissions: {
    goSettingModalTitle: 'Camera permession has been denied',
    goSettingModalMessage: 'Do you you wish to fix this in the settings ?',
    goSettingModalOk: 'Settings',
    goSettingModalCancel: 'Cancel'
  },
  exportEvent: {
    exportSuccess: 'Event successfully exported in your phone calendar',
    exportFail: 'Failed to export event',
    alreadyExported: 'Event already exported in your phone calendar',
    requestFail: 'Failed to export the event, to authorize it you must go to your smartphone settings'
  },
  localNotif: {
    conf: 'The conference  ',
    begin: ' will start in',
    mins: 'Minutes',
    dismiss: 'Dismiss',
    confirmNotif: 'OK'
  },
  pdfSecured: {
    btnTitle: 'PDF',
    titleCodeRequired: 'Please enter the access code that is printed on the back of your badge',
    errorMessage: 'Failed to request pdf',
    serverError: 'Server Error. You can submit later.',
    noNetwork: 'Network unavailable. You can submit later.'
  },
  helpers: {
    update: {
      title: 'Helpers for Update',
      titleChooseDataType: 'Choose your dataType that you want to (update / delete)',
      titleUpdateBtn: 'Update',
      titleDeleteBtn: 'Delete',
      updateSuccess: 'Update completed',
      deleteSuccess: 'Deleted successfully'
    }
  },
  modalPrivacyAgreement: {
    body: '<p> As a user of this application, you have the obligation to indicate to your contact that: As a user of this application, you have the obligation to indicate to your contact that: As as a user of this application, you have the obligation to indicate to your contact that: As a user of this application, you have the obligation to indicate to your contact that: as a user of this application , you have the obligation to indicate to your contact that: As a user of this application, you have the obligation to indicate to your contact that: As a user of this application, you have the obligation to indicate to your contact that: As a user of this application, you have the obligation to indicate to your contact that: As a user of this application, you have the obligation to indicate to your contact que: As a user of this application, you have the obligation to indicate to your contact q ue: As a user of this application, you have the obligation to indicate to your contact that: As a user of this application, you have the obligation to indicate to your contact that: As a user of this application, you have the obligation to indicate to your contact that: As a user of this application, you have the obligation to indicate to your contact that: As a user of this application, you have the obligation to indicate to your contact that: As a user of this application, you have the obligation to indicate to your contact that: As a user of this application, you have the obligation to tell your contact that: </ p ',
    acceptButton: 'I agree',
    hint: 'You must read and accept the contract before continuing'
  },
  social: {
    titleBtn: 'See the video'
  },
  pdfs: {
    title: 'Documents',
    titleBtn: 'pdf'
  }
};