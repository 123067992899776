import { DATA_TYPE_EVENT_CATEGORIES, DATA_TYPE_EXHIBITORS, DATA_TYPE_EXHIBITOR_CATEGORIES, DATA_TYPE_EVENTS, DATA_TYPE_PARTICIPANTS, DATA_TYPE_SPEAKERS, DATA_TYPE_SERVICES, DATA_TYPE_SERVICE_CATEGORIES } from './dataConfig';
import config from './config';
import { LIST_PAGE_KEY, LIST_GROUPS_PAGE_KEY,
// LOGIN_PAGE_KEY,
GOOGLE_MAP_PAGE_KEY, MEDIAS_PAGE_KEY, SEARCH_TAIGA_PAGE_KEY, MOBIGEO_PAGE_KEY, INBOX_PAGE_KEY } from 'src/pages/pagesKeys';
import AdSwap from 'src/components/ad-swap/AdSwap';
import HomeToolbar from 'src/pages/home/HomeToolbar';
import HomeTiles from 'src/pages/home/HomeTiles';
import MobilespotBar from 'src/components/mobilespot-bar/MobilespotBar';
import IphonexFlexMargin from 'src/components/iphonex-flex-margin/IphonexFlexMargin';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import { createItem, createItemRoute } from 'src/core/util/ConfigItemFactory';
import { openUrl } from 'src/core/util/JsTools';
import * as Query from 'src/core/query/Query';
import { AD_BUNDLE_ATTRIBUTION_KEYS } from 'src/core/config-json/ConfigJsonManager';
var LOG_PREF = '[homeConfig] ';

// const { DEFAULT_PROFILE } = require('./profiles');

// NB: used for ChooseProfile page too !
export var getStyle = function getStyle() {
  return {
    background: "url(".concat(getUrl('files/project/home/2000x2000px_AppLeni_BG_PDW.png'), ") no-repeat"),
    backgroundSize: 'contain'
  };
};
export var BASE_COLUMN_COUNT = {
  landscape: 3,
  portrait: 3
};
export var MAX_COLUMN_SIZE = {
  landscape: 190,
  // 2 columns mode => less width available
  portrait: 220
};
export var GENERAL_TILE_OPTIONS = {
  height: 1,
  // rowspan
  width: 1 // colspan
};

// const tileBgGradient = 'linear-gradient(rgba(17, 17, 17, 0.65), rgba(85, 85, 85, 0.65))'

var getServiceIdByLumpMainId = function getServiceIdByLumpMainId(main_id, dataType) {
  var items = Query.getTopCategories(dataType);
  var parentId;
  items && items.map(function (item) {
    if (item.lump.main_id === main_id) {
      parentId = item.id;
    }
  });
  return parentId;
};
var getFiltredCategorie = function getFiltredCategorie(main_id, dataType) {
  var items = Query.getTopCategories(dataType);
  var parentId;
  Boolean(items) && items.map(function (item) {
    if (Boolean(item.lump.main_id) && item.lump.main_id === main_id) {
      parentId = item.id;
    }
  });
  return parentId;
};
var createItemAd = function createItemAd(homebutton, lang, backgroundPosition) {
  return createItem(
  // Style
  {
    container: {
      style: {},
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl(homebutton["img_".concat(lang)]), ")")
      }
      // className: 'home-icon-plan',
    }
  },
  // Label (i18n)
  homebutton["label_".concat(lang)],
  // Action to perform on click
  function (actions) {
    var url = homebutton["link_".concat(lang)],
      target = homebutton["target"];
    if (openUrl(url, null, target, true)) {
      actions.linkClicked(url);
    }
  },
  // Tile options
  GENERAL_TILE_OPTIONS);
};
var getMobigeoTile = function getMobigeoTile() {
  return createItemRoute(
  // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/plan.svg'), ")")
      }
      // className: 'home-icon-plan',
    }
  },
  // Label (i18n)
  'home.map',
  // Page & props
  MOBIGEO_PAGE_KEY, null,
  // Tile options

  GENERAL_TILE_OPTIONS);
};
function getParticipantsTile() {
  if (config.NETWORKING.FEATURE_ENABLED !== true) {
    return null;
  }
  var isAllMode = config.NETWORKING.PARTICIPANTS_DATA_MODE === 'ALL';
  return createItemRoute(
  // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Mise-en-relation.svg'), ")")
      }
    }
  },
  // Label (i18n)
  'home.miseEnRelation',
  // Page & props
  isAllMode ? LIST_PAGE_KEY : SEARCH_TAIGA_PAGE_KEY, isAllMode ? {
    inputs: [{
      dataType: DATA_TYPE_PARTICIPANTS
    }]
  } : {
    dataType: DATA_TYPE_PARTICIPANTS,
    isAdvanced: false
  },
  // Tile options
  GENERAL_TILE_OPTIONS);
}
var getEventsTile = function getEventsTile() {
  return createItemRoute(
  // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/evenements.svg'), ")")
      }
      // className: 'home-icon-programme',
    }
  },
  // Label (i18n)
  'home.agenda',
  // Page & props
  // LIST_GROUPS_PAGE_KEY,
  // { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
  // { inputs: [{ dataType: DATA_TYPE_EVENTS }] },
  LIST_PAGE_KEY, {
    //inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }]
    inputs: [{
      id: getFiltredCategorie('_EVENTS_', DATA_TYPE_EVENT_CATEGORIES),
      dataType: DATA_TYPE_EVENT_CATEGORIES
    }]
  },
  // Tile options
  GENERAL_TILE_OPTIONS);
};
var getEventsFactoryTile = function getEventsFactoryTile() {
  return createItemRoute(
  // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/evenements.svg'), ")")
      }
      // className: 'home-icon-programme',
    }
  },
  // Label (i18n)
  'home.talks',
  // Page & props
  // LIST_GROUPS_PAGE_KEY,
  // { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
  // { inputs: [{ dataType: DATA_TYPE_EVENTS }] },
  LIST_PAGE_KEY, {
    //inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }]
    inputs: [{
      id: getFiltredCategorie('_TALKS_', DATA_TYPE_EVENT_CATEGORIES),
      dataType: DATA_TYPE_EVENT_CATEGORIES
    }]
  },
  // Tile options
  GENERAL_TILE_OPTIONS);
};
var getServicesTile = function getServicesTile() {
  return createItemRoute(
  // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/info.svg'), ")")
      }
      // className: 'home-icon-programme',
    }
  },
  // Label (i18n)
  'home.practicalInfo',
  // Page & props
  LIST_PAGE_KEY,
  // { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
  {
    inputs: [{
      id: getServiceIdByLumpMainId('_BY_SERVICE_', DATA_TYPE_SERVICE_CATEGORIES),
      dataType: DATA_TYPE_SERVICE_CATEGORIES
    }]
  },
  // Tile options
  GENERAL_TILE_OPTIONS);
};
var getNewsTile = function getNewsTile() {
  return createItemRoute(
  // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/actualites.svg'), ")")
      }
      // className: 'home-icon-programme',
    }
  },
  // Label (i18n)
  'home.news',
  // Page & props
  LIST_PAGE_KEY,
  // { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
  {
    inputs: [{
      parentId: getServiceIdByLumpMainId('_BY_ACTUALITE_', DATA_TYPE_SERVICE_CATEGORIES),
      parentType: DATA_TYPE_SERVICE_CATEGORIES,
      dataType: DATA_TYPE_SERVICES
    }]
  },
  // Tile options
  GENERAL_TILE_OPTIONS);
};
var getExhibitorsTile = function getExhibitorsTile() {
  return createItemRoute(
  // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/participant.svg'), ")")
      }
      // className: 'home-icon-programme',
    }
  },
  // Label (i18n)
  'home.exhibitors',
  // Page & props
  LIST_PAGE_KEY,
  // { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
  {
    //inputs: [{ dataType: DATA_TYPE_EXHIBITOR_CATEGORIES }],
    inputs: [{
      id: getFiltredCategorie('_PARTICIPANT_', DATA_TYPE_EXHIBITOR_CATEGORIES),
      dataType: DATA_TYPE_EXHIBITOR_CATEGORIES
    }]
  },
  // Tile options
  GENERAL_TILE_OPTIONS);
};
var getExhibitorsFactoryTile = function getExhibitorsFactoryTile() {
  return createItemRoute(
  // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/participant.svg'), ")")
      }
      // className: 'home-icon-programme',
    }
  },
  // Label (i18n)
  'home.exhibitors',
  // Page & props
  LIST_PAGE_KEY,
  // { inputs: [{ dataType: DATA_TYPE_EVENT_CATEGORIES }], hasGoToSynopticButton: false },
  {
    //inputs: [{ dataType: DATA_TYPE_EXHIBITOR_CATEGORIES }],
    inputs: [{
      id: getFiltredCategorie('_PARTICIPANT_FACTORY_', DATA_TYPE_EXHIBITOR_CATEGORIES),
      dataType: DATA_TYPE_EXHIBITOR_CATEGORIES
    }]
  },
  // Tile options
  GENERAL_TILE_OPTIONS);
};
var getSpeakersTile = function getSpeakersTile() {
  return createItemRoute(
  // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Intervenants.svg'), ")")
      }
      // className: 'home-icon-intervenants',
    }
  },
  // Label (i18n)
  'home.speakers',
  // Page & props
  LIST_PAGE_KEY, {
    contextualTitle: '',
    inputs: [{
      dataType: DATA_TYPE_SPEAKERS
    }]
  },
  // Tile options
  GENERAL_TILE_OPTIONS);
};
var getSocialMediaTile = function getSocialMediaTile() {
  return createItemRoute(
  // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Twitter.svg'), ")")
      }
    }
  },
  // Label (i18n)
  'home.medias',
  // Page & props
  MEDIAS_PAGE_KEY, {
    controlMediaPageContent: {
      // hideTabMediaSocial: true,
      mediaTabKey: 'twitter'
    }
  },
  // Tile options
  GENERAL_TILE_OPTIONS);
};
var getLogoPDW = function getLogoPDW() {
  return createItemRoute(
  // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Logo_original.jpg'), ")")
      }
    }
  },
  // Label (i18n)
  '',
  // Page & props
  '', {},
  // Tile options
  GENERAL_TILE_OPTIONS);
};
var getLogoPDWFactory = function getLogoPDWFactory() {
  return createItemRoute(
  // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Logo_Factory.jpg'), ")")
      }
    }
  },
  // Label (i18n)
  '',
  // Page & props
  '', {},
  // Tile options
  GENERAL_TILE_OPTIONS);
};
var getMapTile = function getMapTile() {
  return createItemRoute(
  // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/plan.svg'), ")")
      }
      // className: 'home-icon-plan',
    }
  },
  // Label (i18n)
  'home.map',
  // Page & props
  GOOGLE_MAP_PAGE_KEY, null,
  // Tile options

  GENERAL_TILE_OPTIONS);
};
var getNotifications = function getNotifications() {
  return createItemRoute(
  // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Notifications.svg'), ")")
      }
      // className: 'home-icon-plan',
    }
  },
  // Label (i18n)
  'home.notifications',
  // Page & props
  INBOX_PAGE_KEY, null,
  // Tile options
  GENERAL_TILE_OPTIONS);
};
var getVideos = function getVideos() {
  return createItemRoute(
  // Style
  {
    container: {
      className: 'home-btn-text'
    },
    icon: {
      style: {
        backgroundImage: "url(".concat(getUrl('files/project/home/Youtube.svg'), ")")
      }
      // className: 'home-icon-plan',
    }
  },
  // Label (i18n)
  'home.videos',
  // Page & props
  MEDIAS_PAGE_KEY, {
    controlMediaPageContent: {
      // hideTabMediaSocial: true,
      mediaTabKey: 'youtube' // to match index of social Media
    }
  },
  // Tile options
  GENERAL_TILE_OPTIONS);
};
var getVisitor = function getVisitor(lang, adConfig, orientation, isLoggedIn) {
  var hasAdButtons = adConfig && adConfig.homebuttons !== null && typeof adConfig.homebuttons === 'object';
  return [{
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME_HEADER
    }
  }, {
    component: HomeToolbar
  }, {
    component: HomeTiles,
    props: {
      baseColumnCount: BASE_COLUMN_COUNT[orientation],
      maxColumnSize: MAX_COLUMN_SIZE[orientation],
      homeContent_Padding: 0,
      tiles: [getLogoPDW(),
      // Exposants
      getExhibitorsTile(),
      // Programme
      getEventsTile(),
      // Speakers
      // getSpeakersTile(),

      // google map PLAN
      getMapTile(),
      // getMobigeoTile(),

      // Mise en relation
      // getParticipantsTile(),

      // Actualites
      getNewsTile(),
      // INFOS PRATIQUES
      getServicesTile(), getLogoPDWFactory(),
      // Exposants
      getExhibitorsFactoryTile(),
      // Programme
      getEventsFactoryTile()

      // Notifications
      // getNotifications(),

      // Social Medias
      // getSocialMediaTile(),

      // Videos
      // getVideos(),
      // INTERACTIVITE ATELIERS
      // hasAdButtons && adConfig.homebuttons.homebutton1 ? createItemAd(adConfig.homebuttons.homebutton1, lang) : null,
      ]
    }
  }, {
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME
    }
  }, {
    component: MobilespotBar
  }, {
    component: IphonexFlexMargin
  }];
};
var getExhibitor = function getExhibitor(lang, adConfig, orientation, isLoggedIn) {
  var hasAdButtons = adConfig && adConfig.homebuttons !== null && typeof adConfig.homebuttons === 'object';
  return [{
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME_HEADER
    }
  }, {
    component: HomeToolbar,
    props: {
      // Black list of buttons just to avoid side effect on older projects
      disabledButtons: ['inbox', 'contactScan']
    }
  }, {
    component: HomeTiles,
    props: {
      baseColumnCount: BASE_COLUMN_COUNT[orientation],
      maxColumnSize: MAX_COLUMN_SIZE[orientation],
      homeContent_Padding: 0,
      tiles: [
      // Exposants
      getExhibitorsTile(),
      // Programme
      getEventsTile(),
      // Speakers
      getSpeakersTile(),
      // google map PLAN
      getMapTile(),
      // getMobigeoTile(),

      // Mise en relation
      getParticipantsTile(),
      // INFOS PRATIQUES
      getServicesTile(),
      // Notifications
      getNotifications(),
      // Social Medias
      getSocialMediaTile(),
      // Videos
      getVideos()

      // INTERACTIVITE ATELIERS
      // hasAdButtons && adConfig.homebuttons.homebutton1 ? createItemAd(adConfig.homebuttons.homebutton1, lang) : null,
      ]
    }
  }, {
    component: AdSwap,
    props: {
      adBundleAttributionKey: AD_BUNDLE_ATTRIBUTION_KEYS.HOME
    }
  }, {
    component: MobilespotBar
  }, {
    component: IphonexFlexMargin
  }];
};

/**
 * Return home configuration
 * @param  {string} profile
 * @param  {string} lang
 * @param  {object} adConfig
 * @param  {string} orientation
 * @param  {boolean} isLoggedIn
 * @return {object}
 */
export function get(profile, lang, adConfig, orientation, isLoggedIn) {
  switch (profile) {
    // PRO
    case 'default':
      return getVisitor(lang, adConfig, orientation, isLoggedIn);
    // GRAND PUBLIC
    case 'exhibitor':
      return getExhibitor(lang, adConfig, orientation, isLoggedIn);
    default:
      if (!profile) {
        console.warn(LOG_PREF + 'No profile set yet');
      } else {
        console.error(LOG_PREF + 'Unsupported profile: ' + profile);
      }
  }
}